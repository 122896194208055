import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { MapIcon } from "@heroicons/react/solid";
import { Footer } from "components";
import { BrowseInsights, NoAVMNotice, PropertiesGrid } from "components/browse";
import { SearchPerformingMarkets } from "components/markets";
import { appConfig } from "config";
import { cn } from "helpers";
import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import TimeAgo from "react-timeago";
import { useSearchParamsState } from "react-use-search-params-state";
import { useRecoilValue } from "recoil";
import { useCurrentStates, useUserSession } from "state";
import {
  filteredSearchResultsNoAVMCountSelector,
  filteredSearchResultsSelector,
  paginationDefaults,
  searchResultCountsAtom,
  searchResultsFiltersAtom,
  SearchResultType,
} from "state/browse";
import { useMarketDataSearchPerformers } from "state/market";
import { useUpdateDate } from "state/proformas";
import { useSelectedProperty } from "state/ui";

type Props = {
  closePanel: () => void;
  searchResults: SearchResultType[];
  onlyFavorites: boolean;
  onlyHidden: boolean;
};

export const ResultsMobile = ({ closePanel, searchResults, onlyFavorites, onlyHidden }: Props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const resultsRef = useRef<HTMLDivElement>(null);
  const searchResultCounts = useRecoilValue(searchResultCountsAtom);
  const { frontendFilteredPropertiesCount } = useRecoilValue(filteredSearchResultsSelector);
  const { selectedProperty, setSelectedProperty } = useSelectedProperty();
  const data = useMarketDataSearchPerformers();
  const performersByMarketAvailable = data.bottom_performers.length > 0 && data.top_performers.length > 0;
  const { currentStates } = useCurrentStates();
  const stateId = currentStates[0];
  const [paginationParams, setPaginationParams] = useSearchParamsState(paginationDefaults);

  const currentUser = useUserSession();
  const [show, setShow] = useState(true);
  const filters = useRecoilValue(searchResultsFiltersAtom);
  const count = useRecoilValue(filteredSearchResultsNoAVMCountSelector);
  const showAVMNotice =
    !currentUser.isAgent &&
    !currentUser.isClient &&
    (filters.min_gross_gain !== null ||
      filters.max_gross_gain !== null ||
      filters.min_gross_income !== null ||
      filters.max_gross_income !== null) &&
    count !== 0;

  useEffect(() => {
    const idx = currentPage * appConfig.browse.defaultPageSize;
    if (
      idx < paginationParams.offset ||
      idx >= paginationParams.offset + appConfig.browse.browseApiPageOffset
    ) {
      setCurrentPage(paginationParams.offset / appConfig.browse.defaultPageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults, paginationParams.offset]);

  const resultsPerPage =
    appConfig.browse.showAd && appConfig.browse.statesWithAds.includes(stateId)
      ? appConfig.browse.defaultPageSize - 1
      : appConfig.browse.defaultPageSize;
  const numPages =
    onlyHidden || onlyFavorites
      ? Math.ceil(searchResults.length / resultsPerPage)
      : Math.ceil((searchResultCounts.total - frontendFilteredPropertiesCount) / resultsPerPage);

  const pagedResults = searchResults.slice(
    currentPage * resultsPerPage - paginationParams.offset,
    (currentPage + 1) * resultsPerPage - paginationParams.offset,
  );
  const updateDateText = useUpdateDate(stateId);

  const handlePageClick = (page: { selected: number }) => {
    setCurrentPage(page.selected);
    setSelectedProperty(undefined);
    const browseOffset = page.selected * resultsPerPage;
    const browseOffsetPage = Math.floor(browseOffset / appConfig.browse.browseApiPageOffset);
    const expectedOffset = browseOffsetPage * appConfig.browse.browseApiPageOffset;
    if (expectedOffset !== paginationParams.offset) {
      setPaginationParams({ offset: expectedOffset });
    }
    resultsRef.current?.scrollTo({ top: 0 });
  };

  useEffect(() => {
    if (selectedProperty) {
      // find selected property in search results and determine the page number
      const idx = searchResults.findIndex((property) => property.parcel_id === selectedProperty.parcel_id);
      const selectedPropertyPage = Math.floor(idx / resultsPerPage);

      if (selectedPropertyPage !== currentPage) {
        setCurrentPage(selectedPropertyPage);
      }
      // open({ canceled: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty]);

  return (
    <>
      <div className="relative h-full overflow-y-scroll" ref={resultsRef}>
        <div
          className={cn(
            "fixed bottom-8 left-1/2 z-[50] h-fit w-fit -translate-x-1/2 items-center justify-center rounded-full bg-black px-5 py-3 text-center text-xs text-white",
            showAVMNotice && show && "bottom-32",
            showAVMNotice && !show && "bottom-12",
            !showAVMNotice && "bottom-8",
          )}
          onClick={closePanel}
        >
          Map
          <MapIcon className="ml-1.5 inline size-4" aria-hidden="true" />
        </div>
        <PropertiesGrid stateId={stateId} properties={pagedResults} selectedProperty={selectedProperty} />
        {numPages > 0 && (
          <ReactPaginate
            pageCount={numPages}
            forcePage={currentPage}
            onPageChange={handlePageClick}
            pageRangeDisplayed={currentPage < 3 || currentPage > numPages - 3 ? 4 : 3}
            marginPagesDisplayed={1}
            previousLabel={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
            nextLabel={<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />}
            containerClassName="mt-4 flex justify-center text-sm text-st-lighter"
            previousClassName="mr-1"
            previousLinkClassName="py-1 hover:text-st-darker cursor-pointer"
            nextClassName="ml-1"
            nextLinkClassName="py-1 hover:text-st-darker cursor-pointer"
            pageClassName="mx-1"
            activeClassName="text-white "
            disabledClassName="text-st-lightest"
            pageLinkClassName="px-2.5 py-1 cursor-pointer border border-[#6389c4] rounded-md"
            activeLinkClassName="px-2.5 py-1 bg-[#6389c4] cursor-default border border-[#6389c4] rounded-md"
            disabledLinkClassName="cursor-default"
            renderOnZeroPageCount={null}
          />
        )}

        <div className="mb-10 mt-20 flex w-full flex-col items-center justify-center px-4">
          <h1 className="mb-10 text-left text-xl font-medium sm:text-3xl">Market Insights</h1>
          {performersByMarketAvailable && (
            <div className="mb-6 flex w-full flex-row flex-wrap justify-around">
              <SearchPerformingMarkets data={data} order="top" />
              <SearchPerformingMarkets data={data} order="bottom" />
            </div>
          )}
          <BrowseInsights stateId={stateId} />
        </div>

        <div className="my-6 flex w-full items-center justify-center text-sm">
          Listings last updated&nbsp;
          <TimeAgo className="inline" date={new Date(updateDateText)} live={false} />
        </div>
        <Footer browsePage={true} />
      </div>
      <NoAVMNotice showAVMNotice={showAVMNotice} show={show} setShow={setShow} />
    </>
  );
};
