import { PeriodSelector } from "components";
import { MarketComparableType } from "components/markets";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { MarketDataEntryType } from "state/market";

const seriesColors = [
  "#020202",
  "#68a691",
  "#964b62",
  "#666da3",
  "#a19a6c",
  "#020202",
  "#68a691",
  "#964b62",
  "#666da3",
  "#a19a6c",
];

type Props = {
  marketResearchData: MarketComparableType[];
  valueName: keyof MarketDataEntryType;
  title: string;
  updated?: boolean;
};

export const MarketResearchGraph = ({ marketResearchData, valueName, title, updated = false }: Props) => {
  const [period, setPeriod] = useState(37);
  const [selectedLine, setSelectedLine] = useState("");
  const [hiddenLines, setHiddenLines] = useState<Set<string>>(new Set());
  const ticks: Date[] = [];
  const strTicks: string[] = [];
  const data: any[] = [];
  const lines: any[] = [];
  const cleanData: any = {};

  // Get the lastDate where marketResearchData was updated
  let today = new Date();
  let lastUpdateMonth: number | undefined = undefined;
  let lastUpdatedDate = new Date().toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  if (marketResearchData[0]?.data && marketResearchData[0]?.data?.length > 0) {
    const lastDate = new Date(marketResearchData[0].data[marketResearchData[0].data.length - 1].month);
    lastUpdateMonth = Number(lastDate.toLocaleString("en-us", { month: "numeric" }));
    if (today.getMonth() + 1 === lastUpdateMonth) {
      lastUpdatedDate = new Date(lastDate.getFullYear(), lastUpdateMonth - 1, 0).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    } else {
      lastUpdatedDate = new Date(lastDate.getFullYear(), lastUpdateMonth, 0).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    }
  }

  // fillTicks()
  let maxLength = 0;
  for (let i = 0; i < marketResearchData.length; i++) {
    if ((marketResearchData[i].data?.length || 0) > maxLength) {
      maxLength = marketResearchData[i].data?.length || 0;
    }
  }
  const months = period > 0 ? period : maxLength || 0;
  for (let i = 0; i < months; i++) {
    const date_ = lastUpdateMonth
      ? today.getMonth() + 1 === lastUpdateMonth
        ? new Date(today.getFullYear(), lastUpdateMonth - 1 - months + i, 1)
        : new Date(today.getFullYear(), lastUpdateMonth - months + i, 1)
      : new Date();
    ticks.push(date_);
    strTicks.push(date_.toISOString().substring(0, 11) + "00:00:00");
  }

  // organizeData()
  marketResearchData.forEach((location) => {
    cleanData[location.id] = { ticks: {}, valueName: valueName };
    location.data?.forEach((item) => {
      cleanData[location.id].ticks[item.month] = item[valueName];
    });
  });

  // buildData()
  strTicks.forEach((currentMonth, idx) => {
    const row: any = {
      name: new Intl.DateTimeFormat("en-US", { year: "numeric", month: "short" }).format(
        new Date(currentMonth),
      ),
    };
    marketResearchData.forEach((location) => {
      const label = `${location.loc_name} ${
        location.structureType !== "all" && location.structureType !== undefined ? location.structureType : ""
      }`;
      const value: number = Number(
        Intl.NumberFormat("en-US", {
          useGrouping: false,
          maximumFractionDigits: 2,
        }).format(cleanData[location.id].ticks[currentMonth]),
      );
      row[label] = value || "N/A";
    });
    data.push(row);
  });

  // buildLines()
  marketResearchData.forEach((location, idx) => {
    const label = `${location.loc_name} ${
      location.structureType !== "all" && location.structureType !== undefined ? location.structureType : ""
    }`;
    lines.push(
      <Line
        className={`${hiddenLines.has(label) ? "opacity-0" : selectedLine === "" || selectedLine === label ? "" : "opacity-40"}`}
        type="linear"
        key={label}
        dataKey={label}
        strokeWidth={selectedLine === label || selectedLine === "" ? 2.5 : 1}
        stroke={seriesColors[idx]}
        animationEasing={selectedLine === label ? "ease-in-out" : "linear"}
        dot={false}
        activeDot={
          hiddenLines.has(label)
            ? false
            : {
                r: 4,
                onMouseOver: () => setSelectedLine(label),
                onClick: () => setSelectedLine(label),
              }
        }
        onMouseOver={() => !hiddenLines.has(label) && setSelectedLine(label)}
        onClick={() => !hiddenLines.has(label) && setSelectedLine(label)}
      />,
    );
  });

  const getMinValue = () => {
    let min = Infinity;
    let hasValidValue = false;
    data.forEach((row) => {
      Object.entries(row).forEach(([key, value]) => {
        if (key !== "name" && !hiddenLines.has(key) && typeof value === "number" && !isNaN(value)) {
          min = Math.min(min, value);
          hasValidValue = true;
        }
      });
    });
    return hasValidValue ? min : null;
  };

  const getMaxValue = () => {
    let max = -Infinity;
    let hasValidValue = false;
    data.forEach((row) => {
      Object.entries(row).forEach(([key, value]) => {
        if (key !== "name" && !hiddenLines.has(key) && typeof value === "number" && !isNaN(value)) {
          max = Math.max(max, value);
          hasValidValue = true;
        }
      });
    });
    return hasValidValue ? max : null;
  };

  const formatValue = (value: number, valueName: string) => {
    if (typeof value !== "number" || isNaN(value)) return "N/A";

    let formattedValue = "";

    if (valueName === "median_value_per_sqft") {
      formattedValue = `$${value.toFixed(0)}`;
    } else if (valueName === "n_new_listings") {
      formattedValue = value.toFixed(0);
    } else if (valueName === "n_sales") {
      formattedValue = value.toFixed(0);
    } else if (valueName === "sale_to_list_price_ratio") {
      formattedValue = value.toFixed(2);
    } else if (valueName === "median_days_on_market") {
      formattedValue = value.toFixed(0) + "  days";
    }

    if (value >= 10000) {
      formattedValue = `${Math.round(value / 1000)}K`;
    }
    return formattedValue;
  };

  const getYearOverYearChange = (currentValue: number, label: string, currentIndex: number) => {
    if (currentIndex < 12 || currentIndex >= data.length) return null;

    const lastYearValue = data[currentIndex - 12][label];

    if (typeof lastYearValue !== "number" || isNaN(lastYearValue) || lastYearValue === 0) return null;
    if (typeof currentValue !== "number" || isNaN(currentValue)) return null;

    const percentChange = ((currentValue - lastYearValue) / lastYearValue) * 100;
    return percentChange;
  };

  return (
    <div className="mb-10">
      <div className="mb-2 flex w-full flex-row">
        <div className="text-md basis-1/2 overflow-hidden text-ellipsis whitespace-nowrap align-top font-medium text-st-lighter">
          {title}
        </div>
        <div className="items-right flex basis-1/2 justify-end text-sm">
          <PeriodSelector period={period} value={13} setPeriod={setPeriod} />
          <PeriodSelector period={period} value={37} setPeriod={setPeriod} />
          <PeriodSelector period={period} value={121} setPeriod={setPeriod} />
          <PeriodSelector period={period} value={0} setPeriod={setPeriod} />
        </div>
      </div>
      <div className="h-full w-full text-xs">
        {data.length > 0 && (
          <ResponsiveContainer width="100%" height={340}>
            <LineChart
              data={data}
              margin={{
                top: 5,
                left:
                  valueName === "median_value_per_sqft"
                    ? -14
                    : valueName === "sale_to_list_price_ratio"
                      ? -24
                      : valueName === "median_days_on_market"
                        ? -2
                        : -16,
                right: 0,
                bottom: 5,
              }}
              onMouseLeave={() => setSelectedLine("")}
              onClick={() => {
                !isMobile && setSelectedLine("");
              }}
            >
              <CartesianGrid syncWithTicks={true} vertical={false} stroke="#e0e0e0" horizontal={false} />
              <XAxis
                dataKey="name"
                tickMargin={10}
                interval={0}
                tick={({ x, y, payload, index }) => {
                  if (index === 0 || index === data.length - 1) {
                    return (
                      <g transform={`translate(${x},${y})`}>
                        <text
                          x={0}
                          y={10}
                          textAnchor={index === 0 ? "start" : "end"}
                          fontSize={12}
                          fill="#4B5563"
                        >
                          {payload.value}
                        </text>
                      </g>
                    );
                  }
                  return <g />;
                }}
                axisLine={{ stroke: "#e0e0e0" }}
                tickLine={false}
              />
              <YAxis
                type="number"
                tickMargin={5}
                domain={[(getMinValue() ?? 0) as number, (getMaxValue() ?? 100) as number]}
                ticks={[(getMinValue() ?? 0) as number, (getMaxValue() ?? 100) as number]}
                padding={{ top: 0, bottom: 0 }}
                axisLine={{ stroke: "#e0e0e0" }}
                tickLine={false}
                interval={0}
                tick={({ x, y, payload, index }) => {
                  if (index === 0 || index === 1) {
                    const yOffset = index === 0 ? 0 : 10;
                    return (
                      <g transform={`translate(${x},${y})`}>
                        <text x={0} y={yOffset} textAnchor="end" fontSize={12} fill="#4B5563">
                          {formatValue(payload.value, valueName)}
                        </text>
                      </g>
                    );
                  }
                  return <g />;
                }}
                orientation="left"
              />
              <Tooltip
                offset={200}
                labelStyle={{ fontSize: 12, fontWeight: "bold" }}
                itemStyle={{ fontSize: 12 }}
                cursor={{
                  stroke: "#000000",
                  strokeWidth: 2,
                }}
                content={({ active, payload, label, coordinate }) => {
                  if (active && payload && payload.length && coordinate) {
                    const chartWidth = document.querySelector(".recharts-wrapper")?.clientWidth || 0;
                    const chartHeight = document.querySelector(".recharts-wrapper")?.clientHeight || 0;

                    // Filter out hidden lines from the tooltip
                    const visiblePayload = payload.filter(
                      (entry) => entry.name && !hiddenLines.has(String(entry.name)),
                    );

                    // Find the active point from the selected line
                    const activePoint = visiblePayload.find((p) => p.name === selectedLine);
                    const activeCoordinate = activePoint?.payload?.activeCoordinate ?? coordinate;

                    const tooltipX =
                      (activeCoordinate?.x ?? 0) > chartWidth / 2
                        ? (activeCoordinate?.x ?? 0) - 200
                        : (activeCoordinate?.x ?? 0) + 10;

                    const dotY = activeCoordinate?.y ?? 0;
                    const tooltipY = dotY > chartHeight / 2 ? dotY - 80 : dotY + 20;

                    return (
                      <div
                        className={`absolute w-40 min-w-fit rounded-lg border border-gray-300 bg-white xl:w-48`}
                        style={{
                          left: tooltipX,
                          top: tooltipY,
                        }}
                      >
                        <div className="rounded-t-lg bg-black p-2 font-bold text-white">{label}</div>
                        <div className="ml-1 flex flex-col gap-1 p-2">
                          {visiblePayload.map((entry: any, index: number) => {
                            const currentIndex = data.findIndex((d) => d.name === label);
                            const yearChange = getYearOverYearChange(entry.value, entry.name, currentIndex);

                            return (
                              <div
                                className={`flex flex-col ${selectedLine === entry.name || selectedLine === "" ? "font-bold" : "opacity-60"}`}
                                key={`item-${index}`}
                                style={{ color: entry.color }}
                              >
                                <div className="flex flex-row items-start justify-between whitespace-nowrap">
                                  <div className="flex-cols flex items-center justify-between">
                                    <span
                                      className="mr-1.5 size-1.5 min-w-fit rounded-full text-left"
                                      style={{ backgroundColor: entry.color }}
                                    />
                                    <span className="grow text-left">
                                      {(() => {
                                        const text = entry.name
                                          .replace(/condominium$/, "condos")
                                          .replace(/house$/, "houses");
                                        const lastSpaceIndex = text.lastIndexOf(" ");
                                        if (lastSpaceIndex === -1) return text;
                                        return (
                                          <>
                                            {text.slice(0, lastSpaceIndex)}
                                            <br />
                                            {text.slice(lastSpaceIndex + 1).toUpperCase()}
                                          </>
                                        );
                                      })()}
                                    </span>
                                  </div>
                                  <div className="flex flex-col items-end justify-between">
                                    <div className="min-w-fit text-right">
                                      {formatValue(entry.value, valueName)}
                                    </div>
                                    <div>
                                      {yearChange !== null ? (
                                        <>
                                          {yearChange > 0 ? "+" : ""}
                                          {yearChange.toFixed(1)}%
                                        </>
                                      ) : (
                                        <>N/A</>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Legend
                verticalAlign="top"
                content={({ payload }) => (
                  <div className="relative mb-8 h-fit">
                    <ul className="flex flex-wrap justify-center gap-x-4">
                      {payload?.map((entry, index) => (
                        <li
                          key={`item-${index}`}
                          className={`${hiddenLines.has(entry.value) ? "opacity-40" : ""} mb-2 flex cursor-pointer flex-row items-center`}
                          onClick={(e) => {
                            setHiddenLines((prev) => {
                              const newSet = new Set(prev);
                              if (newSet.has(entry.value)) {
                                newSet.delete(entry.value);
                              } else {
                                newSet.add(entry.value);
                              }
                              return newSet;
                            });
                          }}
                        >
                          <span
                            className="mr-1.5 size-1.5 min-w-fit rounded-full text-left"
                            style={{ backgroundColor: entry.color }}
                          />
                          <span style={{ color: entry.color }}>
                            {(() => {
                              const text = entry.value
                                .replace(/condominium$/, "condos")
                                .replace(/house$/, "houses");
                              const lastSpaceIndex = text.lastIndexOf(" ");
                              if (lastSpaceIndex === -1) return text;
                              return (
                                <>
                                  {text.slice(0, lastSpaceIndex)}{" "}
                                  {text.slice(lastSpaceIndex + 1).toUpperCase()}
                                </>
                              );
                            })()}
                          </span>
                        </li>
                      ))}
                    </ul>
                    <div className="text-center text-xs text-gray-500">(click to show/hide)</div>
                  </div>
                )}
              />
              {lines}
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
      {updated && (
        <div className="flex w-full items-center justify-center pt-10 text-xs">
          Data last updated&nbsp;{lastUpdatedDate}
        </div>
      )}
    </div>
  );
};
