import { useRecoilState } from "recoil";
import { userHiddenFilterAtom } from "state";

export const HiddenFilter = () => {
  const [hiddenFilter, setHiddenFilter] = useRecoilState(userHiddenFilterAtom);
  return (
    <div className="mt-2 flex h-9 w-full flex-row items-center justify-between gap-1 sm:gap-4">
      <div className="relative flex w-1/3 whitespace-nowrap">
        Properties
        {/* Bagde */}
        {/* <span className="sr-only">New filter badge</span>
        <span
          className={cn(
            "ml-2 mt-1 inline-flex h-4 w-fit items-center justify-center rounded-sm border-red-500 bg-red-500 px-2 text-[10px] font-bold text-white",
          )}
        >
          {isMobile ? "NEW" : "NEW FILTER"}
        </span> */}
      </div>
      <div className="flex w-2/3 flex-row items-center justify-start">
        <input
          id="assumable_loan"
          className="mr-2 border outline-0 indeterminate:bg-gray-300 focus:ring-0"
          type="checkbox"
          value="true"
          checked={hiddenFilter}
          onChange={() => setHiddenFilter(!hiddenFilter)}
        />
        <label htmlFor="assumable_loan" className="whitespace-pre text-left">
          Hide hidden properties
        </label>
      </div>
    </div>
  );
};
