import { appConfig } from "config";
import { useFetchWrapper } from "helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { useAlerts, useSignInSignUpModal, useUserPreferences, useUserSession } from "state";

export function useUserActions() {
  const fetchWrapper = useFetchWrapper();
  const currentUser = useUserSession();
  const alertActions = useAlerts();
  const navigate = useNavigate();
  const location = useLocation();
  const { closeSignInSignUpModal } = useSignInSignUpModal();
  const { resetPreferences, sincronizePreferences } = useUserPreferences();

  return {
    login,
    logout,
    register,
    resendConfirmationEmail,
    forgotPassword,
    resetPassword,
    changePassword,
    updateUser,
    uploadAvatar,
  };

  function login(userData: any, dontRedirect: boolean | undefined) {
    return fetchWrapper
      .post(appConfig.apiEndpoint.login, userData)
      .then((user) => {
        sincronizePreferences();
        currentUser.refresh(user);

        if (dontRedirect) {
          closeSignInSignUpModal();
        } else {
          // get return url from location state or default to home page
          const state = location.state as { from: { pathname: string; search: string } };
          const from = state?.from?.pathname + state?.from?.search || "/";
          navigate(from, { replace: true });
        }
      })
      .catch((err) => {
        console.log("Login error:", err, userData.email);
      });
  }

  function logout() {
    resetPreferences();
    return fetchWrapper
      .post(appConfig.apiEndpoint.logout)
      .then(() => {
        currentUser.refresh();
        navigate("/");
      })
      .catch((err) => {
        console.log("Logout error:", err);
      });
  }

  function register(userData: any) {
    return fetchWrapper.post(appConfig.apiEndpoint.register, userData);
  }

  function resendConfirmationEmail(email: any, dontRedirect: boolean | undefined) {
    return fetchWrapper
      .get(appConfig.apiEndpoint.requestEmailConfirmation.replace(":email", email))
      .then(() => {
        alertActions.success("Confirmation email resent. Please check your email.");
        if (dontRedirect) {
          closeSignInSignUpModal();
        } else {
          // get return url from location state or default to home page
          const state = location.state as { from: { pathname: string; search: string } };
          const from = state?.from?.pathname + state?.from?.search || "/";
          navigate(from, { replace: true });
        }
      })
      .catch((err) => {
        console.log("resendConfirmationEmail error:", err, email);
      });
  }

  function forgotPassword(userData: any, dontRedirect: boolean | undefined) {
    return fetchWrapper
      .post(appConfig.apiEndpoint.forgotPassword, userData)
      .then((data) => {
        alertActions.success("Reset successful. Please check your email for instructions.");
        if (!dontRedirect) {
          setTimeout(() => navigate("/"), 5000);
        }
      })
      .catch((err) => {
        console.log("forgotPassword error:", err, userData.email);
      });
  }

  function resetPassword(token: string, userData: any) {
    return fetchWrapper
      .post(`${appConfig.apiEndpoint.resetPassword}${token}`, userData)
      .then((user) => {
        currentUser.refresh(user);

        alertActions.success("Reset successful. Redirecting to Home...");

        setTimeout(() => navigate("/"), 3000);
      })
      .catch((err) => {
        console.log("resetPassword error:", err);
      });
  }

  function changePassword(formData: any) {
    return fetchWrapper.post(appConfig.apiEndpoint.changePassword, formData).then((user) => {
      alertActions.success("Password changed successfully.");
    });
  }

  function updateUser(userData: any) {
    return fetchWrapper
      .post(appConfig.apiEndpoint.user, userData)
      .then((user) => {
        currentUser.refresh({
          name_first: user.name_first,
          name_last: user.name_last,
          phone_number: user.phone_number,
          default_region: user.default_region,
          description: userData.description,
          skils: userData.skills,
          service_areas: userData.service_areas,
        });
      })
      .catch((err) => {
        console.log("updateUser error:", err);
      });
  }

  function uploadAvatar(blob: Blob) {
    const formData = new FormData();
    formData.append("file", blob, "profile.jpg");
    const requestOptions: RequestInit = {
      method: "POST",
      credentials: "include",
      body: formData,
    };
    return fetch(appConfig.apiEndpoint.uploadAvatar, requestOptions)
      .then((res) => res.json())
      .then((avatar) => {
        currentUser.refresh({ avatar });
      });
  }
}
