import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { useRecoilValue } from "recoil";
import { filteredSearchResultsSelector, searchResultCountsAtom, SearchResultType } from "state/browse";
import { onlyHiddenAtom } from "state/useUserPreferences";

interface Props {
  isPaneOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  numActiveFilters: number;
  onResetAllFilters: () => void;
  searchResults: SearchResultType[];
  onlyFavorites: boolean;
}

export const SlidingPanel = ({
  isPaneOpen,
  onClose,
  children,
  numActiveFilters,
  onResetAllFilters,
  searchResults,
  onlyFavorites,
}: Props) => {
  const onlyHidden = useRecoilValue(onlyHiddenAtom);
  const searchResultCounts = useRecoilValue(searchResultCountsAtom);
  const { frontendFilteredPropertiesCount } = useRecoilValue(filteredSearchResultsSelector);

  return (
    <Transition show={isPaneOpen}>
      <div className="fixed inset-0 z-50 h-full overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          {/* Background overlay */}
          <Transition.Child
            enter="transition-opacity ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute inset-0 bg-gray-900 opacity-50" onClick={onClose}></div>
          </Transition.Child>

          {/* Sliding panel */}
          <Transition.Child
            enter="transition-transform ease-out duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition-transform ease-in duration-300"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
            className="absolute inset-y-0 right-0 flex w-full max-w-xl"
          >
            <div className="flex h-full w-full flex-col overflow-y-auto bg-white">
              <div className="flex touch-none items-center justify-between border-b p-4 py-4">
                <span className="text-lg font-bold">All Filters</span>
                <button className="text-st-lighter hover:text-st-normal" onClick={onClose}>
                  <XIcon className="mr-2 block h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div className="mb-32 flex grow flex-col pb-2">{children}</div>

              <div className="absolute bottom-0 z-10 flex w-full flex-row items-center border-t bg-white p-4 px-6 text-center">
                <div className="flex flex-row">
                  <button
                    className="items-center rounded-sm bg-[#6389c4] px-4 py-2 text-sm font-normal text-white shadow-lg transition duration-150 ease-in-out hover:bg-[#4674b9] sm:h-10 sm:px-8"
                    onClick={onClose}
                  >
                    Search
                  </button>
                </div>
                <div>
                  {numActiveFilters > 0 && (
                    <button
                      className="ml-4 whitespace-nowrap text-sm text-[#6389c4] hover:text-st-normal hover:underline"
                      onClick={onResetAllFilters}
                    >
                      Reset
                      <br />
                      filters
                    </button>
                  )}
                </div>
                <div className="w-full justify-end pt-1 text-right text-xs">
                  <span className="font-bold">
                    {(onlyHidden || onlyFavorites) && searchResults.length}
                    {!onlyHidden &&
                      !onlyFavorites &&
                      searchResultCounts.total - frontendFilteredPropertiesCount}{" "}
                    matches
                  </span>{" "}
                  out of
                  <span className="font-bold"> {searchResultCounts.total}</span> listings
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  );
};
