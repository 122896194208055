import { atom, useRecoilState } from "recoil";
import { CustomStructureType } from "state/proformas/";
import { RentalComparableType, SalesComparableType } from "state/proformas/comparables/";

export type CalcSheetType = {
  current_value_estimate: number;
  annual_taxes: number;
  annualized_appreciation: number;
  carrying_time: number;
  costs: any[];
  holding_time: number;
  include_appreciation: boolean;
  is_brrr: boolean | null;
  loans: any[];
  monthly_rent_brrr: number;
  monthly_rent_hold: number;
  pellego_arv: number;
  property_appreciation: number;
  purchase_price: number;
  rehab_costs_flip: number;
  rehab_costs_hold: number;
  rehab_level_flip: string;
  rehab_level_hold: string;
  rent_appreciation: number;
  rent_period: number;
  rental: any[];
  resale_value: number;
  original_resale_value: number;
  mortgage_length?: number;
  my_arv?: number;
};

export type PhotoType = {
  _id: number;
  photo_url: string;
  description?: string;
  thumbnail_url: string;
  display_order: number;
  sale_id: number;
  parcel_id: number;
};

export type ProformaType = {
  _id: number;
  isDataComplete?: boolean;
  isRestError?: boolean;
  isGeoData?: boolean;
  admin_status: string;
  calcsheet: CalcSheetType;
  comparables: SalesComparableType[];
  constraints_passed: number;
  created_on: string | null;
  custom_structure: CustomStructureType;
  formatted_address?: string;
  full_address: string;
  has_mls: boolean;
  is_mine: boolean;
  labels: string[] | null;
  listing_number: string;
  loans: any[];
  listing: any;
  managing_agent_notes: string | null;
  name: string;
  notes: string | null;
  package_amounts: any[];
  parcel_id: number;
  parcel: any;
  photo_count: number;
  photos: PhotoType[];
  proforma_type: string;
  rental_comparables: RentalComparableType[];
  sale_id: number;
  structure_id: number;
  structure: any;
  thumbnail_url: string;
  user_id: number;
  wholesale: any;
  is_wholesale: boolean;
  updated_on: string | null;
};

export const proformaAtom = atom<ProformaType>({
  key: "proformaAtom",
  default: {} as ProformaType,
});

export const useProforma = () => {
  const [proforma, setProforma] = useRecoilState(proformaAtom);

  return {
    proforma,
    setProforma,
  };
};
