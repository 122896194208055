import { StarIcon } from "@heroicons/react/outline";
import { AdjustmentsIcon, StarIcon as SolidStarIcon, XIcon } from "@heroicons/react/solid";
import { AnnounceWidget, WithSignIn } from "components";
import { FiltersMobile, NonMLSPanel, ResultsMobile } from "components/browse";
import { CancelActiveIcon, CancelIcon } from "components/icons";
import { useState } from "react";
import { animated, useSpring } from "react-spring";
import { useDrag } from "react-use-gesture";
import { useRecoilState, useRecoilValue } from "recoil";
import { onlyHiddenAtom, useRecentSearches, useUserSession } from "state";
import {
  filteredSearchResultsSelector,
  searchResultCountsAtom,
  SearchResultType,
  useFilters,
} from "state/browse";

type Props = {
  loading: boolean;
  searchResults: SearchResultType[];
  emptyResult: boolean;
  onlyFavorites: boolean;
  setOnlyFavorites: (value: boolean) => void;
};

export const PanelMobile = ({
  loading,
  searchResults,
  emptyResult,
  onlyFavorites,
  setOnlyFavorites,
}: Props) => {
  const currentUser = useUserSession();
  const [onlyHidden, setOnlyHidden] = useRecoilState(onlyHiddenAtom);
  const searchResultCounts = useRecoilValue(searchResultCountsAtom);
  const { frontendFilteredPropertiesCount } = useRecoilValue(filteredSearchResultsSelector);
  const [filtersPanelOpen, setFiltersPanelOpen] = useState(false);
  const { numActiveFilters } = useFilters();
  const [isPanelOpened, setIsPanelOpened] = useState(false);

  useRecentSearches({});

  const [{ y }, set] = useSpring(() => ({
    y: window.innerHeight - 70,
  }));

  const open = ({ canceled }: { canceled: boolean }) => {
    set({
      y: 0,
      immediate: false,
      config: canceled ? { tension: 200, friction: 20 } : { tension: 100, friction: 25 },
    });
    setIsPanelOpened(true);
  };

  const close = (velocity = 0) => {
    setFiltersPanelOpen(false);
    setIsPanelOpened(false);
    set({
      y: window.innerHeight - 70,
      immediate: false,
      config: { tension: 200, friction: 20, clamp: true, velocity },
    });
  };

  const bind = useDrag(
    ({ last, vxvy: [, vy], movement: [, my], cancel, canceled }) => {
      if (my < -10) cancel();
      if (last) {
        my > window.innerHeight * 0.85 || vy > 0.2 ? close(vy) : open({ canceled });
      } else {
        set({ y: my, immediate: true });
      }
    },
    {
      initial: () => [0, y.get()],
      useTouch: false,
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    },
  );

  const display = y.to((py) => (py < window.innerHeight ? "block" : "none"));

  return (
    <animated.div
      style={{ display, bottom: 0, y }}
      className="absolute left-0 right-0 z-[1100] mx-0.5 h-[100dvh] touch-none items-center overflow-y-auto rounded-t-3xl border border-gray-300 bg-white shadow-lg"
    >
      {/* PANEL HEADER */}
      <div {...bind()} className="cursor-grab active:cursor-grabbing">
        <div className="flex h-5 w-full items-center justify-center">
          <div className="h-1 w-10 rounded-full bg-gray-300"></div>
        </div>

        <div className="sticky top-0 z-20 flex size-full touch-none bg-white px-4 pb-6 pt-2">
          {emptyResult && !isPanelOpened && (
            <div className="flex w-full flex-row items-center justify-between whitespace-nowrap">
              No results found
            </div>
          )}
          {!filtersPanelOpen && !emptyResult && (
            <div className="flex w-full flex-row items-center justify-between whitespace-nowrap">
              <div className="w-fit text-sm">
                <span className="font-bold">
                  {(onlyHidden || onlyFavorites) && searchResults.length}
                  {!onlyHidden &&
                    !onlyFavorites &&
                    searchResultCounts.total - frontendFilteredPropertiesCount}
                </span>{" "}
                out of
                <span className="font-bold">&nbsp;{searchResultCounts.totalUnfiltered}</span> listings
                <span
                  className="inline-block cursor-pointer"
                  onClick={() => {
                    setOnlyHidden(!onlyHidden);
                    setOnlyFavorites(false);
                  }}
                >
                  {onlyHidden ? (
                    <CancelActiveIcon className="ml-2 inline size-5" aria-hidden="true" />
                  ) : (
                    <CancelIcon className="ml-2 inline size-5" aria-hidden="true" />
                  )}
                </span>
                <span
                  className="inline-block cursor-pointer"
                  onClick={() => {
                    currentUser.isLogged && setOnlyFavorites(!onlyFavorites);
                    setOnlyHidden(false);
                  }}
                >
                  <WithSignIn useChildren>
                    {onlyFavorites ? (
                      <SolidStarIcon
                        className="ml-2 inline size-[22px] text-yellow-500 shadow-xl"
                        aria-hidden="true"
                      />
                    ) : (
                      <StarIcon className="ml-2 inline size-5 shadow-xl" aria-hidden="true" />
                    )}
                  </WithSignIn>
                </span>
              </div>
              <div className="w-fit justify-center">
                {!loading && !emptyResult && (
                  <button
                    className={`text-md ml-2 flex items-center justify-between rounded-md px-2 text-left text-sm font-medium ${
                      numActiveFilters > 0 ? "bg-black text-white" : "text-st-light"
                    }`}
                    onClick={(e) => {
                      setFiltersPanelOpen(true);
                      open({ canceled: false });
                    }}
                  >
                    <span className="flex items-center whitespace-nowrap p-1">
                      <AdjustmentsIcon className="block size-5" aria-hidden="true" />
                      {numActiveFilters > 0 ? ` • ${numActiveFilters}` : ""}
                    </span>
                  </button>
                )}
              </div>
            </div>
          )}

          {filtersPanelOpen && (
            <div className="flex size-full flex-row items-center justify-between whitespace-nowrap px-2">
              <AdjustmentsIcon className="mr-2 size-5" aria-hidden="true" />
              <div className="text-sm text-st-darkest">
                <span className="font-bold">
                  {(onlyHidden || onlyFavorites) && searchResults.length}
                  {!onlyHidden &&
                    !onlyFavorites &&
                    searchResultCounts.total - frontendFilteredPropertiesCount}
                </span>{" "}
                out of
                <span className="font-bold">&nbsp;{searchResultCounts.totalUnfiltered}</span> listings
              </div>
              <button className="justify-center" onClick={() => setFiltersPanelOpen(false)}>
                <XIcon className="size-5" aria-hidden="true" />
              </button>
            </div>
          )}
        </div>
      </div>

      {/* PANEL BODY */}
      <div className="relative flex h-[calc(100dvh-61.31px)] w-full flex-1 grow flex-col overflow-y-auto">
        {filtersPanelOpen && <FiltersMobile setFiltersPanelOpen={setFiltersPanelOpen} />}
        {!filtersPanelOpen &&
          (emptyResult ? (
            <div className="overflow-y-auto">
              <NonMLSPanel />
              <AnnounceWidget />
            </div>
          ) : (
            <ResultsMobile
              closePanel={close}
              searchResults={searchResults}
              onlyFavorites={onlyFavorites}
              onlyHidden={onlyHidden}
            />
          ))}
      </div>
    </animated.div>
  );
};
