import { formatCurrencyK1, updateUrlParams } from "helpers";
import isEqual from "lodash/isEqual";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { onlyHiddenAtom, userHiddenFilterAtom, userPreferencesAtom, useUserSession } from "state";
import { searchResultsAtom } from "state/browse";

export type StructureType = "House" | "Mobile" | "Condominium" | "Multifamily" | "Townhouse";
type ListingType = "Standard" | "Bank Owned" | "Short Sale";
type ListingEventType = "New Listing" | "Price Change" | "Back on Market";

export interface IFilters {
  assumable_loan: boolean | null;
  hide_unknown_equity: boolean | null;
  listing_event: ListingEventType[] | null;
  listing_remarks: string[] | null;
  listing_type: ListingType[] | null;
  max_bathrooms: number | null;
  max_bedrooms: number | null;
  max_days_on_market: number | null;
  max_equity: number | null;
  max_gross_gain: number | null;
  max_gross_income: number | null;
  max_listing_price: number | null;
  max_lot_square_feet: number | null;
  max_square_feet_finished: number | null;
  max_unfinished_basement: number | null;
  max_updated: number | string | null;
  max_year_built: number | null;
  min_bathrooms: number | null;
  min_bedrooms: number | null;
  min_days_on_market: number | null;
  min_equity: number | null;
  min_gross_gain: number | null;
  min_gross_income: number | null;
  min_listing_price: number | null;
  min_lot_square_feet: number | null;
  min_square_feet_finished: number | null;
  min_unfinished_basement: number | null;
  min_updated: number | string | null;
  min_year_built: number | null;
  structure_type: StructureType[] | null;
}

export const searchResultsFiltersAtom = atom<IFilters>({
  key: "searchResultsFiltersAtom",
  default: {} as IFilters,
});

export const restrictNoAVMAtom = atom<boolean>({
  key: "restrictNoAVMAtom",
  default: true,
});

export const filteredSearchResultsSelector = selector({
  key: "filteredSearchResultsSelector",
  get: ({ get }) => {
    const hiddenFilter = get(userHiddenFilterAtom);
    const onlyHidden = get(onlyHiddenAtom);

    let result = get(searchResultsAtom);
    let frontendFilteredPropertiesCount = 0;
    const resultOriginaCount = result.length;

    // Filter out properties that have no AVM
    const restrictNoAVM = get(restrictNoAVMAtom);
    if (restrictNoAVM) {
      result = result.filter((property) => !property.no_avm_display);
      frontendFilteredPropertiesCount = resultOriginaCount - result.length;
    }

    const preferences = get(userPreferencesAtom);
    const hiddenProperties = preferences?.hidden_properties || {};

    // Filter out properties that are hidden from total
    if (hiddenFilter && !onlyHidden) {
      result = result.filter((property) => !hiddenProperties[property.parcel_id]);
      frontendFilteredPropertiesCount = resultOriginaCount - result.length;
    }

    // Show only properties that are hidden from total
    if (onlyHidden) {
      result = result.filter((property) => hiddenProperties[property.parcel_id]);
    }

    return { result, frontendFilteredPropertiesCount };
  },
});

export const filteredSearchResultsNoAVMCountSelector = selector({
  key: "filteredSearchResultsNoAVMCountSelector",
  get: ({ get }) => {
    let result = get(searchResultsAtom);

    let count = 0;
    const restrictNoAVM = get(restrictNoAVMAtom);
    if (restrictNoAVM) {
      count = result.filter((property) => property.no_avm_display).length;
    }

    return count;
  },
});

export const EQUIVALENT_STRUCT_TYPES = [
  "Duplex",
  "Triplex",
  "Fourplex",
  "Multifamily",
  "MultiFamily",
  "Multi family",
  "Multi Family",
  "Multi-Family",
  "Quadruplex",
  "Quadplex",
  "Fiveplex",
];

export type StructureTypeOption = {
  name: string;
  value: string;
};

export const structureTypeOptions: StructureTypeOption[] = [
  { name: "Houses", value: "House" },
  { name: "Mobiles", value: "Mobile" },
  { name: "Condos", value: "Condominium" },
  { name: "Multi", value: "Multifamily" },
  { name: "Town", value: "Townhouse" },
];

export const listingTypeOptions: ListingType[] = ["Standard", "Bank Owned", "Short Sale"];
export const listingEventOptions: ListingEventType[] = ["New Listing", "Back on Market", "Price Change"];

interface IFiltersParams {
  name: keyof IFilters;
  type: "array" | "number" | "boolean" | "string";
  default: any;
}

export const filtersList: IFiltersParams[] = [
  { name: "assumable_loan", type: "boolean", default: false },
  { name: "hide_unknown_equity", type: "boolean", default: false },
  { name: "listing_event", type: "array", default: listingEventOptions },
  { name: "listing_remarks", type: "array", default: null },
  { name: "listing_type", type: "array", default: listingTypeOptions },
  { name: "max_bathrooms", type: "number", default: null },
  { name: "max_bedrooms", type: "number", default: null },
  { name: "max_days_on_market", type: "number", default: null },
  { name: "max_equity", type: "number", default: null },
  { name: "max_gross_gain", type: "number", default: null },
  { name: "max_gross_income", type: "number", default: null },
  { name: "max_listing_price", type: "number", default: null },
  { name: "max_lot_square_feet", type: "number", default: null },
  { name: "max_square_feet_finished", type: "number", default: null },
  { name: "max_unfinished_basement", type: "number", default: null },
  { name: "max_updated", type: "number", default: null },
  { name: "max_year_built", type: "number", default: null },
  { name: "min_bathrooms", type: "number", default: null },
  { name: "min_bedrooms", type: "number", default: null },
  { name: "min_days_on_market", type: "number", default: null },
  { name: "min_equity", type: "number", default: null },
  { name: "min_gross_gain", type: "number", default: null },
  { name: "min_gross_income", type: "number", default: null },
  { name: "min_listing_price", type: "number", default: null },
  { name: "min_lot_square_feet", type: "number", default: null },
  { name: "min_square_feet_finished", type: "number", default: null },
  { name: "min_unfinished_basement", type: "number", default: null },
  { name: "min_updated", type: "number", default: null },
  { name: "min_year_built", type: "number", default: null },
  { name: "structure_type", type: "array", default: [] },
];

export const filtersTexts: Record<keyof IFilters, string> = {
  assumable_loan: "Assumable Loan :value",
  hide_unknown_equity: "Hide Unknown Equity :value",
  listing_event: "Listing Event :value",
  listing_remarks: "Listing Remarks :value",
  listing_type: "Listing Type :value",
  max_bathrooms: "Max Bathrooms :value",
  max_bedrooms: "Max Bedrooms :value",
  max_days_on_market: "Max Days on Market :value",
  max_equity: "Max Equity :value%",
  max_gross_gain: "Max Gross Gain :value%",
  max_gross_income: "Max Income :value%",
  max_listing_price: "Max Price $:value",
  max_lot_square_feet: "Max Lot Sqft :value",
  max_square_feet_finished: "Max Finished Sqft :value",
  max_unfinished_basement: "Max Unfinished Sqft :value",
  max_updated: "Max Days since last update :value",
  max_year_built: "Max Year Built :value",
  min_bathrooms: "Min Bathrooms :value",
  min_bedrooms: "Min Bedrooms :value",
  min_days_on_market: "Min Days on Market :value",
  min_equity: "Min Equity :value%",
  min_gross_gain: "Min Gross Gain :value%",
  min_gross_income: "Min Income :value%",
  min_listing_price: "Min Price $:value",
  min_lot_square_feet: "Min Lot Sqft :value",
  min_square_feet_finished: "Min Finished Sqft :value",
  min_unfinished_basement: "Min Unfinished Sqft :value",
  min_updated: "Min Days since last update :value",
  min_year_built: "Min Year Built :value",
  structure_type: "Structure Type :value",
};

const paramToBool = (urlParams: URLSearchParams, paramName: string, defaultValue: boolean) => {
  const paramValue = urlParams.get(paramName);
  // The only presence of a boolean param (value === '') is considered true
  if (paramValue === "true" || paramValue === "") return true;
  if (paramValue === "false") return false;

  return defaultValue;
};

const paramToNum = (urlParams: URLSearchParams, paramName: string, defaultValue: number | null) => {
  const paramValue = urlParams.get(paramName);
  if (paramValue || paramValue === "0") return Number(paramValue);

  return defaultValue;
};

const paramToArray = (urlParams: URLSearchParams, paramName: string, defaultValue: any[] | null) => {
  const paramValue = urlParams.getAll(paramName);
  if (paramValue && paramValue.length > 0) return paramValue;

  return defaultValue;
};

const paramsToFilters = (params: URLSearchParams): any => {
  const filters = {} as IFilters;

  filtersList.forEach((filter) => {
    switch (filter.type) {
      case "array":
        filters[filter.name] = paramToArray(params, filter.name, filter.default) as never;
        break;
      case "number":
        filters[filter.name] = paramToNum(params, filter.name, filter.default) as never;
        break;
      case "boolean":
        filters[filter.name] = paramToBool(params, filter.name, filter.default) as never;
        break;
      case "string":
        filters[filter.name] = (params.get(filter.name) as never) || filter.default;
        break;
    }
  });
  return filters;
};

export const numActiveFiltersSelector = selector({
  key: "numActiveFiltersSelector",
  get: ({ get }) => {
    const filters = get(searchResultsFiltersAtom);
    let numActiveFilters = 0;

    filtersList.forEach((filter) => {
      // if filter is min/max, only increment one time if any or both are active
      if (filter.name.startsWith("min_")) {
        if (!isEqual(filters[filter.name], filter.default)) {
          numActiveFilters++;
        }
      } else if (filter.name.startsWith("max_")) {
        const minName = filter.name.replace("max_", "min_");
        if (
          isEqual(filters[minName as keyof IFilters], filter.default) &&
          !isEqual(filters[filter.name], filter.default)
        ) {
          numActiveFilters++;
        }
      } else {
        if (!isEqual(filters[filter.name], filter.default)) {
          numActiveFilters++;
        }
      }
    });
    return numActiveFilters;
  },
});

export const resetedFilterValues = () => {
  const resetedFilters = {} as IFilters;

  filtersList.forEach((filter) => {
    resetedFilters[filter.name] = filter.default;
  });
  return resetedFilters;
};

const nonDefaultValue = (filter: IFilters, filterName: string) => {
  const defaultValue = filtersList.find((filter) => filter.name === filterName)?.default;
  if (filter[filterName as keyof IFilters] !== defaultValue) {
    return filter[filterName as keyof IFilters];
  } else {
    return null;
  }
};

export const buildBrowseTitleFromUrl = (url: string, city: string = "") => {
  if (url.includes("?")) {
    url = url.split("?")[1];
  }
  const urlParams = new URLSearchParams(url);
  const filters = paramsToFilters(urlParams);
  const texts = [];
  if (Object.keys(filters).length === 0) return city;

  let value = nonDefaultValue(filters, "min_gross_gain");
  if (value !== null) {
    texts.push(`${value}%+ Gain`);
  }
  value = nonDefaultValue(filters, "min_gross_income");
  if (value !== null) {
    texts.push(`${value}%+ Income`);
  }
  value = nonDefaultValue(filters, "min_equity");
  if (value !== null) {
    texts.push(`${value}%${Number(value) < 100 ? "+" : ""} Equity`);
  }
  if (nonDefaultValue(filters, "assumable_loan")) {
    texts.push("Assumable Loan");
  }
  let resultText = texts.join(", ");
  resultText += resultText.length > 0 ? ` ${city} ` : `${city} `;

  const priceMin = nonDefaultValue(filters, "min_listing_price");
  const priceMax = nonDefaultValue(filters, "max_listing_price");
  if (priceMin && priceMax) {
    resultText += `${formatCurrencyK1(priceMin as number)}-${formatCurrencyK1(priceMax as number)}`;
  } else if (priceMin) {
    resultText += `${formatCurrencyK1(priceMin as number)}+`;
  } else if (priceMax) {
    resultText += `<${formatCurrencyK1(priceMax as number)}`;
  }

  return resultText;
};

export const useFilters = () => {
  const [filters, setFilters] = useRecoilState(searchResultsFiltersAtom);
  const setRestrictNoAVM = useSetRecoilState(restrictNoAVMAtom);
  const numActiveFilters = useRecoilValue(numActiveFiltersSelector);
  const [urlParams, setUrlParams] = useSearchParams();
  const currentUser = useUserSession();

  useEffect(() => {
    const filtersFromParams = paramsToFilters(urlParams);
    if (isEqual(filtersFromParams, filters)) return;
    setFilters(filtersFromParams);
  }, [urlParams, setFilters, filters]);

  const filterOutNoAVM =
    (filters.min_gross_gain !== null ||
      filters.max_gross_gain !== null ||
      filters.min_gross_income !== null ||
      filters.max_gross_income !== null) &&
    !currentUser.isAgent &&
    !currentUser.isClient;

  useEffect(() => {
    // Properties that have the no_avm_display === true should not show up
    // when Gross Gain or Income filters are active
    setRestrictNoAVM(filterOutNoAVM);
  }, [filterOutNoAVM, setRestrictNoAVM]);

  const updateFilters = (newFilters: any) => {
    updateUrlParams(urlParams, newFilters, setUrlParams);
  };

  return {
    filters,
    setFilters: updateFilters,
    numActiveFilters,
  };
};
