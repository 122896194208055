import { AnnounceWidget, LoadingPhrases, NavBrowse } from "components";
import {
  AppreciationsMap,
  BrowseMapDesktop,
  FiltersDesktop,
  NonMLSPanel,
  ResultsPanelDesktop,
} from "components/browse";
import { titleCase } from "helpers";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { useUserSession } from "state";
import {
  buildBrowseTitleFromUrl,
  filteredSearchResultsSelector,
  useBrowse,
  useLocationSearch,
} from "state/browse";
import { useSavedDeals, useSaveDeal } from "state/proformas";

const onlyFavoritesResults = (properties: any[], isSavedDeal: (parcelId: number) => boolean) => {
  return properties.filter((property) => isSavedDeal(property.parcel_id));
};

export const BrowseDesktop = () => {
  const { loading, emptyResult } = useBrowse();
  const [onlyFavorites, setOnlyFavorites] = useState(false);

  const currentUser = useUserSession();

  useSavedDeals(currentUser.isLogged);
  const { isSavedDeal } = useSaveDeal();
  const { result: filteredSearchResults } = useRecoilValue(filteredSearchResultsSelector);
  const searchResults = onlyFavorites
    ? onlyFavoritesResults(filteredSearchResults, isSavedDeal)
    : filteredSearchResults;
  const { locationSearchItems } = useLocationSearch();
  const pageTitle = buildBrowseTitleFromUrl(
    window.location.pathname + window.location.search,
    titleCase(locationSearchItems[0]?.name),
  );

  const loadingPhrases = ["Pulling Listings", "Estimating ARVs", "Estimating Rents", "Populating Data"];
  // Check if there are any search params. If not redirect to home using useNavigate
  const navigate = useNavigate();
  if (window.location.search.length === 0) {
    navigate("/");
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div id="main-frame" className="grid h-screen w-screen place-items-center overscroll-y-none">
        <div id="top-navbar" className="fixed top-0 z-40 flex h-16 w-full flex-row justify-between">
          <NavBrowse loading={loading} emptyResult={emptyResult} searchResults={searchResults} />
          <FiltersDesktop searchResults={searchResults} onlyFavorites={onlyFavorites} />
        </div>
        <div id="main-content" className="fixed top-16 h-screen w-screen place-items-center">
          {loading ? (
            <LoadingPhrases phrases={loadingPhrases} />
          ) : (
            <div className="flex h-screen w-full flex-row">
              <div id="map-section" className="z-10 h-[calc(100vh-4rem)] w-full grow">
                {emptyResult ? <AppreciationsMap /> : <BrowseMapDesktop />}
              </div>
              <div
                id="results-section"
                className="flex h-full w-[calc(318px+2rem)] shrink-0 xl:w-[calc(636px+3rem)] 3xl:w-[calc(954px+4rem)]"
              >
                {emptyResult ? (
                  <>
                    <NonMLSPanel />
                    <AnnounceWidget />
                  </>
                ) : (
                  <ResultsPanelDesktop
                    searchResults={searchResults}
                    onlyFavorites={onlyFavorites}
                    setOnlyFavorites={setOnlyFavorites}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
