export function CancelActiveIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} transform="rotate(90)">
      <g id="SVGRepo_iconCarrier" strokeWidth="2">
        <circle cx="12" cy="12" r="9" stroke="currentColor" />
        <path d="M18 18L6 6" stroke="#e81717" />
      </g>
    </svg>
  );
}
