import { highlightHandlerAtom, PropertyPhotos, Restricted } from "components/browse";
import { isPellego } from "config";
import { buildProformasAddressUrl, buildProformasListingNumberUrl, formatCurrencyK1 } from "helpers";
import { useRef } from "react";
import NumberFormat from "react-number-format";
import { usePopperTooltip } from "react-popper-tooltip";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { onlyHiddenAtom, userHiddenFilterAtom, useUserPreferences, useUserSession } from "state";
import { SearchResultType } from "state/browse";

type Props = {
  property: SearchResultType;
  showCosts: boolean;
  overMap?: boolean;
};

export const PropertyMobile = ({ property, showCosts, overMap = false }: Props) => {
  const selectedPropertyRef = useRef<HTMLDivElement>(null);
  const hiddenFilter = useRecoilValue(userHiddenFilterAtom);
  const onlyHidden = useRecoilValue(onlyHiddenAtom);
  const { preferences } = useUserPreferences();

  const isHidden = preferences?.hidden_properties?.[property?.parcel_id] || false;

  const highlightHandler = useRecoilValue(highlightHandlerAtom);
  const showAddress = !(property.dont_show_address === true);
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible: toolTipVisible,
  } = usePopperTooltip({ placement: "auto" });
  const currentUser = useUserSession();
  const notClientNorAgent = !currentUser.isClient && !currentUser.isAgent;

  const onMouseHover = (propertyId: number | null) => {
    // Highlight property on Map
    if (highlightHandler) {
      highlightHandler(propertyId);
    }
  };

  const propertyUrl = showAddress
    ? buildProformasAddressUrl(property.fullAddress)
    : buildProformasListingNumberUrl(property.listingNumber);

  const origin = isPellego ? "https://app.pellego.com" : "https://app.lotside.com";
  const shareUrl = `${origin}${propertyUrl}`;

  if (isHidden && hiddenFilter && !onlyHidden) {
    return null;
  }

  return (
    <Link to={propertyUrl} target="_blank">
      <div
        ref={selectedPropertyRef}
        className="flex min-w-full flex-col whitespace-nowrap rounded-2xl bg-white"
        onMouseEnter={() => onMouseHover(property.parcel_id)}
        onMouseLeave={() => onMouseHover(null)}
      >
        <div className="relative aspect-[3/2] w-full flex-shrink-0">
          <PropertyPhotos property={property} shareUrl={shareUrl} overMap={overMap} />
        </div>
        <div className="w-full bg-white p-3 pt-2 text-center text-xs font-medium text-st-lightest">
          {property.mortgage_remaining_principal && (
            <span>
              {`${formatCurrencyK1(property.mortgage_remaining_principal)} Balance`}
              {(property.mortgage_interest_rate || property.assumable_loan) && " • "}
            </span>
          )}
          {property.mortgage_interest_rate && `${property.mortgage_interest_rate / 100}% Interest`}
          {property.mortgage_interest_rate && property.assumable_loan && " • "}
          {property.assumable_loan && "Assumable Loan"}
        </div>

        <div className="text-center text-[10px]">
          <div ref={setTriggerRef}>
            {toolTipVisible && property.listing_source === "RMLS" && (
              <div ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container" })}>
                <div {...getArrowProps({ className: "tooltip-arrow" })}></div>
                ARV, Rent, and profit metrics are estimated by {isPellego ? "Pellego" : "Lotside"}'s
                Algorithms.
              </div>
            )}
            <div className="flex flex-row gap-1 px-2 pb-3 text-left">
              <div className="flex w-full flex-col px-2 text-sm font-light">
                <div className="flex flex-row whitespace-nowrap">
                  <div className="">ARV</div>
                  <div className="w-full text-right">
                    {property.no_avm_display && notClientNorAgent ? (
                      <Restricted />
                    ) : (
                      <NumberFormat
                        value={property.pellegoArv}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$ "
                        decimalScale={0}
                      />
                    )}
                  </div>
                </div>

                <div className="flex flex-row whitespace-nowrap">
                  <div className="">Price</div>
                  <div className="w-full text-right">
                    <NumberFormat
                      value={property.listingPrice}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$ "
                      decimalScale={0}
                    />
                  </div>
                </div>
              </div>
              <div className="flex w-0.5 max-w-0.5 flex-col border-l"></div>
              <div className="flex w-full flex-col px-2 text-sm font-light xl:px-3">
                <div className="flex flex-row whitespace-nowrap">
                  <div className="">Rent</div>
                  <div className="w-full text-right">
                    <NumberFormat
                      value={property.monthly_rent_hold}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$ "
                      decimalScale={0}
                    />
                    /mo
                  </div>
                </div>

                <div className="flex flex-row whitespace-nowrap">
                  {/* <div className="">{property.hoa_dues > 0 ? "PITIH" : "PITI"}</div> */}
                  <div className="">PITI</div>
                  <div className="w-full text-right">
                    <NumberFormat
                      // value={property.hoa_dues > 0 ? property.piti + property.hoa_dues : property.piti}
                      value={property.piti}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$ "
                      decimalScale={0}
                    />
                    /mo
                  </div>
                </div>
              </div>
            </div>
            {showCosts && (
              <div className="mx-4 mb-4 line-clamp-6 h-28 border-t py-4 text-left text-xs">
                {property.listing_remarks}
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};
