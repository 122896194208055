import "./instrument";
import { ClickToComponent } from "click-to-react-component";
// import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";
import "./index.css";
import { App } from "./App";
import { appInit } from "./config";

// import reportWebVitals from "./reportWebVitals";

appInit();
const root = createRoot(document.getElementById("root")!);
root.render(
  // <StrictMode>
  <>
    <ClickToComponent editor={process.env.REACT_APP_EDITOR || "vscode-insiders"} />
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </>,
  // </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
