import { appConfig } from "config";
import { fetcherWithCredentials } from "helpers";
import { atom, useRecoilState } from "recoil";
import { useUserSession } from "./useUserSession";

interface PropertyPreference {
  formattedAddress: string;
  fullAddress: string;
}

export interface UserPreferences {
  hidden_properties?: {
    [parcelId: number]: PropertyPreference;
  };
  viewed_properties?: {
    [parcelId: number]: PropertyPreference;
  };
}

// Create a sync key for broadcasting changes
const SYNC_KEY = "preferences-sync";

export const userHiddenFilterAtom = atom<boolean>({
  key: "userHiddenFilterState",
  default: true,
});

export const onlyHiddenAtom = atom<boolean>({
  key: "onlyHiddenState",
  default: false,
});

export const userPreferencesAtom = atom<UserPreferences>({
  key: "userPreferencesState",
  default: JSON.parse(localStorage.getItem("user_preferences") || "{}"),
  effects: [
    ({ setSelf, onSet }) => {
      // Listen for changes from other tabs
      const broadcastChannel = new BroadcastChannel(SYNC_KEY);

      broadcastChannel.onmessage = (event) => {
        setSelf(event.data);
      };

      // Broadcast changes to other tabs
      onSet((newValue) => {
        broadcastChannel.postMessage(newValue);
      });

      // Cleanup
      return () => {
        broadcastChannel.close();
      };
    },
  ],
});

export function useUserPreferences() {
  const currentUser = useUserSession();
  const [userPreferences, setUserPreferences] = useRecoilState(userPreferencesAtom);

  // Update preferences in local storage and API
  const updatePreferences = async (
    newPreferences: Partial<UserPreferences>,
    action: "add" | "delete" = "add",
  ) => {
    const storageData = JSON.parse(localStorage.getItem("user_preferences") || "{}");

    const mergedData =
      action === "delete"
        ? {
            hidden_properties: Object.fromEntries(
              Object.entries(storageData?.hidden_properties || {}).filter(
                ([id]) => !newPreferences?.hidden_properties?.[Number(id)],
              ),
            ),
            viewed_properties: Object.fromEntries(
              Object.entries(storageData?.viewed_properties || {}).filter(
                ([id]) => !newPreferences?.viewed_properties?.[Number(id)],
              ),
            ),
          }
        : {
            hidden_properties: {
              ...(storageData?.hidden_properties || {}),
              ...(newPreferences?.hidden_properties || {}),
            },
            viewed_properties: {
              ...(storageData?.viewed_properties || {}),
              ...(newPreferences?.viewed_properties || {}),
            },
          };

    if (JSON.stringify(mergedData) !== JSON.stringify(storageData)) {
      localStorage.setItem("user_preferences", JSON.stringify(mergedData));
      setUserPreferences(mergedData);
      if (currentUser.isLogged) {
        try {
          await fetcherWithCredentials(`${appConfig.apiEndpoint.preferences}`, "POST", mergedData);
          currentUser.refresh({ preferences: mergedData });
        } catch (err) {
          console.error("Failed to update preferences:", err);
          throw err;
        }
      }
    }
  };

  const sincronizePreferences = async () => {
    const data = await fetcherWithCredentials(`${appConfig.apiEndpoint.preferences}`, "GET");
    localStorage.setItem("user_preferences", JSON.stringify(data));
    setUserPreferences(data);
  };

  const resetPreferences = () => {
    const preferences = {
      hidden_properties: {},
      viewed_properties: {},
    };
    localStorage.removeItem("user_preferences");
    setUserPreferences(preferences);
  };

  return {
    preferences: userPreferences,
    updatePreferences,
    sincronizePreferences,
    resetPreferences,
  };
}
