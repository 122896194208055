import { LoadingPhrases, RegisterClientOrAgentModal } from "components";
import { BrowseMapMobile, NoResultsMobileMap, PanelMobile } from "components/browse";
import { titleCase } from "helpers";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { useUserSession } from "state";
import {
  buildBrowseTitleFromUrl,
  filteredSearchResultsSelector,
  useBrowse,
  useLocationSearch,
} from "state/browse";
import { useSavedDeals, useSaveDeal } from "state/proformas";

const onlyFavoritesResults = (properties: any[], isSavedDeal: (parcelId: number) => boolean) => {
  return properties.filter((property) => isSavedDeal(property.parcel_id));
};

export const BrowseMobile = () => {
  const { loading, emptyResult } = useBrowse();
  const [onlyFavorites, setOnlyFavorites] = useState(false);

  const currentUser = useUserSession();
  useSavedDeals(currentUser.isLogged);
  const { isSavedDeal } = useSaveDeal();
  const { result: filteredSearchResults } = useRecoilValue(filteredSearchResultsSelector);
  const searchResults = onlyFavorites
    ? onlyFavoritesResults(filteredSearchResults, isSavedDeal)
    : filteredSearchResults;
  const { locationSearchItems } = useLocationSearch();
  const pageTitle = buildBrowseTitleFromUrl(
    window.location.pathname + window.location.search,
    titleCase(locationSearchItems[0]?.name),
  );

  const loadingPhrases = ["Pulling Listings", "Estimating ARVs", "Estimating Rents", "Populating Data"];
  const navigate = useNavigate();
  if (window.location.search.length === 0) {
    navigate("/");
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div id="main-frame" className="grid h-screen w-screen place-items-center overscroll-none">
        <div id="main-content" className="h-screen w-screen place-items-center overflow-hidden">
          {loading ? (
            <LoadingPhrases phrases={loadingPhrases} />
          ) : (
            <div className="flex h-screen w-full flex-row overflow-hidden">
              <div id="results-section" className="relative flex h-full w-full shrink-0">
                <div className="relative flex h-[100dvh] w-full flex-col overflow-hidden">
                  <div className="size-full overflow-hidden bg-transparent">
                    {emptyResult ? <NoResultsMobileMap /> : <BrowseMapMobile emptyResult={false} />}
                    <PanelMobile
                      loading={loading}
                      searchResults={searchResults}
                      emptyResult={emptyResult}
                      onlyFavorites={onlyFavorites}
                      setOnlyFavorites={setOnlyFavorites}
                    />
                  </div>
                  <RegisterClientOrAgentModal />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
