import { Map } from "components/browse";
import { useEffect } from "react";
import { useSelectedProperty, useUserPreferences } from "state";

export const BrowseMapDesktop = () => {
  const { selectedProperty } = useSelectedProperty();
  const { updatePreferences } = useUserPreferences();

  useEffect(() => {
    if (selectedProperty) {
      updatePreferences({
        viewed_properties: {
          [selectedProperty.parcel_id]: {
            formattedAddress: selectedProperty?.formatted_address || "",
            fullAddress: selectedProperty?.fullAddress || "",
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty]);

  return (
    <div className="flex h-full w-full flex-col overflow-y-auto bg-[#aadaff]">
      <div className="z-10 h-full w-full overflow-y-auto bg-[#aadaff]">
        <Map emptyResult={false} />
      </div>
    </div>
  );
};
