import { ChevronLeftIcon, ChevronRightIcon, StarIcon } from "@heroicons/react/outline";
import { StarIcon as SolidStarIcon } from "@heroicons/react/solid";
import { Spinner, WithSignIn } from "components";
import { LegendsOverPhotosMobile, PhotosListingDisclosure } from "components/browse";
import { CancelActiveIcon, CancelIcon, PinIcon } from "components/icons";
import { StreetView } from "components/proformas/details";
import { cn } from "helpers";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { animated, useSpring } from "react-spring";
import { useSwipeable } from "react-swipeable";
import { useUserPreferences, useUserSession } from "state";
import { useSaveDeal, useSavedPropertiesList } from "state/proformas";

// import { ShareIcon } from "components/icons";
// import { Share } from "./Share";

type Props = {
  property: any;
  shareUrl?: string;
  overMap?: boolean;
};

export const PropertyPhotos = ({ property, shareUrl = "", overMap = false }: Props) => {
  const { preferences } = useUserPreferences();
  const isHidden = preferences?.hidden_properties?.[property?.parcel_id] || false;
  const [photoIdx, setPhotoIdx] = useState(0);
  const [mouseOver, setMouseOver] = useState(false);
  const [saving, setSaving] = useState(false);
  const [swipeDirection, setSwipeDirection] = useState<"next" | "prev" | null>(null);
  const currentPhoto = (property.photo_urls && property.photo_urls[photoIdx]) || property.photo || null;
  const inErrorImage = "https://cdn.davinci.pellego.com/static/images/core/missing.png";
  const { isSavedDeal, saveDeal, deleteSavedDeal } = useSaveDeal();
  const currentUser = useUserSession();
  const { mutate: mutateSavedProperties } = useSavedPropertiesList();
  const { updatePreferences } = useUserPreferences();

  const getNextPhotoIdx = (direction: "next" | "prev") => {
    if (direction === "next") {
      return (photoIdx + 1) % property.photo_urls.length;
    } else {
      return photoIdx > 0 ? photoIdx - 1 : property.photo_urls.length - 1;
    }
  };

  useEffect(() => {
    const nextPhoto = new Image();
    nextPhoto.src = property.photo_urls[getNextPhotoIdx("next")];
    const prevPhoto = new Image();
    prevPhoto.src = property.photo_urls[getNextPhotoIdx("prev")];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoIdx, property.photo_urls]);

  const onClickNext = (e: React.MouseEvent) => {
    e.preventDefault();
    setPhotoIdx(getNextPhotoIdx("next"));
  };

  const onClickPrev = (e: React.MouseEvent) => {
    e.preventDefault();
    setPhotoIdx(getNextPhotoIdx("prev"));
  };

  const onSaveProperty = (e: React.MouseEvent) => {
    e.preventDefault();
    setSaving(true);
    saveDeal(property._id, property.parcel_id).finally(() => {
      mutateSavedProperties();
      setSaving(false);
    });
  };

  const onUnSaveProperty = (e: React.MouseEvent) => {
    e.preventDefault();
    setSaving(true);
    deleteSavedDeal(property.parcel_id).finally(() => {
      mutateSavedProperties();
      setSaving(false);
    });
  };

  const [spring, setSpring] = useSpring(() => ({
    x: 0,
    config: { tension: 300, friction: 30 },
  }));

  const totalPhotos = property.photo_urls ? property.photo_urls.length : 0;
  const pointsLength = totalPhotos ? (totalPhotos < 5 ? totalPhotos : 5) : 0;

  // Determine if a point should be fully white
  const isPointActive = (index: number) => {
    if (index === 0) {
      return photoIdx === 0;
    }
    if (index === pointsLength - 1) {
      return photoIdx === totalPhotos - 1;
    }

    if (index > 0 && index < pointsLength - 1 && photoIdx > 0 && photoIdx < totalPhotos - 1) {
      const jump = totalPhotos / (pointsLength - 2);
      return Math.floor(photoIdx / jump) + 1 === index;
    }
    return false;
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (totalPhotos > 1) {
        setSwipeDirection("next");
        setSpring({
          x: -100,
          onRest: () => {
            setSpring({ x: 0, immediate: true });
            setPhotoIdx(getNextPhotoIdx("next"));
            setSwipeDirection(null);
          },
        });
      }
    },
    onSwipedRight: () => {
      if (totalPhotos > 1) {
        setSwipeDirection("prev");
        setSpring({
          x: 100,
          onRest: () => {
            setSpring({ x: 0, immediate: true });
            setPhotoIdx(getNextPhotoIdx("prev"));
            setSwipeDirection(null);
          },
        });
      }
    },
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const handleHideProperty = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (isHidden) {
      updatePreferences(
        { hidden_properties: { [property.parcel_id]: { formattedAddress: "", fullAddress: "" } } },
        "delete",
      );
    } else {
      updatePreferences({
        hidden_properties: {
          [property.parcel_id]: {
            formattedAddress: property?.formatted_address,
            fullAddress: property?.fullAddress,
          },
        },
      });
    }
  };

  // const handleShare = (e: React.MouseEvent) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setIsShareModalOpen(true);
  // };

  return (
    <div
      {...swipeHandlers}
      className={cn(
        "bg-[background-size: 100% 100%;] relative flex h-full w-full items-end overflow-hidden bg-cover bg-center bg-no-repeat",
        isMobile ? "rounded-2xl" : "rounded-t-md",
        overMap && isMobile && "rounded-b-none rounded-t-2xl",
      )}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <animated.div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url('${currentPhoto}')`,
          transform: spring.x.to((x) => `translateX(${x}%)`),
        }}
      />
      <animated.div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url('${property.photo_urls[getNextPhotoIdx(swipeDirection || "next")]}')`,
          transform: spring.x.to((x) => `translateX(${x < 0 ? x + 100 : x - 100}%)`),
        }}
      />
      {!currentPhoto && (
        <StreetView
          otherClasses="flex cursor-pointer items-end"
          sizeClasses={`rounded-t-${overMap ? "2xl" : "md"} bg-cover bg-center bg-no-repeat size-full`}
          parcel={property}
          titles={false}
          rounded={`rounded-t-${overMap ? "2xl" : "sm"}`}
          inErrorImage={inErrorImage}
        />
      )}
      {isMobile && <LegendsOverPhotosMobile property={property} />}
      {!isMobile && <PhotosListingDisclosure property={property} />}
      <div className={`absolute ${isMobile ? "top-10" : "top-0"} z-10 flex flex-col`}>
        {property.assumable_loan && !isMobile && (
          <div className="mx-2 mt-1 flex">
            <div className="rounded-md bg-black px-2 py-1 text-[9px] text-white">Assumable Loan</div>
          </div>
        )}
        {property.sqftUnfinished > 0 && (
          <div className="mx-2 mt-1 flex">
            <div className="rounded-md bg-black px-2 py-1 text-[9px] text-white">Unfinished Sqft</div>
          </div>
        )}
        {property.listingType !== "Standard" && (
          <div className="mx-2 mt-1 flex">
            <div className="rounded-md bg-black px-2 py-1 text-[9px] text-white">{property.listingType}</div>
          </div>
        )}
        {currentUser.isClient && property.is_wholesale && (
          <div className="mx-2 mt-1 flex rounded-md bg-black px-2 py-1">
            <div className="justify-left flex items-center">
              <PinIcon className="z-10 mr-2 size-3 text-white" />
              <div className="text-[9px] text-white">Assignment Contract</div>
            </div>
          </div>
        )}
      </div>

      {/* Top right icons */}
      <div
        className={`absolute right-3 top-2.5 z-10 flex w-fit flex-row items-center justify-end gap-3 ${
          isMobile ? "text-gray-200" : "text-white"
        }`}
      >
        {/* {isMobile && <ShareIcon className="size-6" onClick={handleShare} aria-hidden="true" />} */}
        <div onClick={handleHideProperty}>
          {isHidden ? (
            <CancelActiveIcon className="size-6" aria-hidden="true" />
          ) : (
            <CancelIcon className="size-6" aria-hidden="true" />
          )}
        </div>
        <WithSignIn useChildren>
          {saving ? (
            <Spinner className="size-6" />
          ) : (
            <>
              {isSavedDeal(property.parcel_id) ? (
                <SolidStarIcon
                  onClick={(e) => currentUser.isLogged && onUnSaveProperty(e)}
                  className="size-6"
                  aria-hidden="true"
                />
              ) : (
                <StarIcon
                  onClick={(e) => currentUser.isLogged && onSaveProperty(e)}
                  className="size-6"
                  aria-hidden="true"
                />
              )}
            </>
          )}
        </WithSignIn>
      </div>

      {/* Navigation */}
      {isMobile && totalPhotos > 1 ? (
        <div className="absolute bottom-3 left-0 right-0 z-10 flex justify-center space-x-2">
          <div className="flex items-center justify-center">
            {[...Array(pointsLength)].map((_, index) => (
              <div
                key={index}
                className={`mx-[3px] size-[5px] rounded-full ${isPointActive(index) ? "bg-white" : "bg-white/50"}`}
              />
            ))}
          </div>
        </div>
      ) : (
        mouseOver &&
        totalPhotos > 1 && (
          <>
            <button
              className="z-9 bg-black/1 absolute left-0 h-full w-20 items-center px-0 py-0"
              onClick={onClickPrev}
            >
              <ChevronLeftIcon
                className="align-left z-10 ml-0 h-8 w-8 text-white drop-shadow-md hover:text-sl-lightest"
                aria-hidden="true"
              />
            </button>
            <button
              className="z-9 bg-black/1 absolute right-0 flex h-full w-20 items-center justify-end px-0 py-0 text-right"
              onClick={onClickNext}
            >
              <ChevronRightIcon
                className="align-right z-10 mr-0 h-8 w-8 text-white drop-shadow-md hover:text-sl-lightest"
                aria-hidden="true"
              />
            </button>
          </>
        )
      )}

      {/* Shadows */}
      {isMobile && (
        <>
          <div className="absolute top-0 h-7 w-full bg-gradient-to-b from-gray-900/90 to-gray-900/70"></div>
          <div className="absolute top-7 h-9 w-full bg-gradient-to-b from-gray-900/70 to-transparent"></div>
        </>
      )}
      {!isMobile && (
        <div className="absolute right-0 top-0 size-32 bg-[radial-gradient(circle_at_top_right,rgba(17,24,39,0.3)_0%,rgba(17,24,39,0)_70%)]"></div>
      )}
      <div className="absolute bottom-0 h-7 w-full bg-gradient-to-t from-gray-900/90 to-gray-900/70"></div>
      <div className="absolute bottom-7 h-9 w-full bg-gradient-to-t from-gray-900/70 to-transparent"></div>
      {/* {isShareModalOpen && <Share shareUrl={shareUrl} onClose={() => setIsShareModalOpen(false)} />} */}
    </div>
  );
};
